/* this is the stylesheet for the Authenticator Modal */

.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

[data-amplify-authenticator] {
  --amplify-components-fieldcontrol-focus-box-shadow: transparent;
  --amplify-components-authenticator-router-border-width: 0px;
  --amplify-components-authenticator-router-box-shadow: transparent;
}

[type="submit"] {
  margin-top: 2rem;
}

.amplify-button {
  background-color: var(--vercee-purple);
  color: white;
}

.amplify-button:active {
  border: none;
}

.amplify-button--small {
  background: none;
  color: var(--vercee-purple);
  font-weight: bold !important;
}

.amplify-button--small:active {
  background: none;
  color: var(--vercee-purple);
  font-weight: bold !important;
      -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;
   outline: none;
}

.amplify-flex {
  font-size: medium;
  font-weight: bold;
  height: fit-content;
}

.amplify-heading {
  padding: 0px !important;
  font-size: x-large;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amplify-input {
  font-size: small;
  font-weight: normal;
}

.amplify-tabs {
  background-color: var(--vercee-purple);
}

.amplify-tabs__item {
  background-color: rgb(243, 243, 243);
  color: var(--vercee-purple);
  border-color: transparent;
    -webkit-box-shadow: inset 0px 0px 5px #000000 !important;
     -moz-box-shadow: inset 0px 0px 5px #000000 !important;
          box-shadow: inset 0px 0px 5px #000000 !important;
   outline: none;
}

.amplify-tabs__item--active {
  background-color: white;
  color: var(--vercee-purple);
    -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;
   outline: none;
}

.amplify-tabs__item:hover {
  color: var(--vercee-purple);
}

.amplify-tabs__item--active:hover {
  color: var(--vercee-purple);
        -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important;
}

.amplify-tabs__item:active {
  color: var(--vercee-purple);
  border-color: transparent;
}

button .amplify-text {
  color: white;
}

.amplify-text {
  font-size: medium;
}

.amplify-tabs__panel.amplify-tabs__panel--active div form {
  padding-bottom: 6px;
  padding-top: 16px;
}

 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /* MEDIA QUERIES */

/* Extra small devices (xs): Mobile portrait, less than 480px */
@media (max-width: 479px) {

  .amplify-label {
    height: 1rem !important;
    font-size: x-small !important;
  }

  .federated-sign-in-container button {
    box-sizing: border-box !important;
  }

  .federated-sign-in-container button:focus {
    border: none;
  }

  .federated-sign-in-container {
    font-size: xx-small !important;
  }

  .amplify-tabs__panel.amplify-tabs__panel--active div form {
    padding: 12px;
  }

  [role="tab"] {
    font-size: small;
  }

  /* show password */
  [role="switch"] {
    height: 1.2rem !important;
  }

  [type="submit"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2rem;
  }

  .amplify-field {
    font-size: xx-small !important;
    height: 3rem;
  }

  .amplify-authenticator {
    font-size: x-small !important;
  }

  .amplify-button--small {
    background: none;
    color: var(--vercee-purple);
    padding: 0px !important;
    font-size: smaller;
    font-weight: bold !important;
  }

  .amplify-input {
    padding-left: 2.2rem !important;
    font-size: small !important;
    width: 60vw;
  }

  .amplify-input::placeholder {
    padding-left: .2rem !important;
    font-size: small !important;
  }

  .amplify-heading {
    padding: 0px !important;
    padding-top: .5rem !important;
    text-align: center !important;
    font-size: medium !important;
  }

  .amplify-flex, [type="date"] {
    font-size: small !important;
  }

  .amplify-tabs__list {
    height: 3rem !important;
  }

  .amplify-tabs__panel {
    padding: 0px !important;
  }

  .amplify-tabs__panel div form {
    height: fit-content;
  }

  .amplify-tabs__panel div form input {
    padding: .2rem !important;
    height: 1.3rem;
  }

  .amplify-tabs__item {
    height: 3rem;
    font-size: x-small !important;
  }

  .amplify-text {
    font-size: x-small !important;
  }
}

/* Small devices (sm): Mobile landscape, 480px to 639px */
@media (min-width: 480px) and (max-width: 639px) {

  .amplify-tabs__panel.amplify-tabs__panel--active div form {
    padding: 24px;
  }

  [role="tab"] {
    font-size: small;
  }

  [role="switch"] {
    height: 1.3rem !important;
  }


  [type="submit"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2rem;
  }

  .amplify-field {
    font-size: small;
    display: flex;
    height: 3rem;
  }

  .amplify-button--small {
    background: none;
    color: var(--vercee-purple);
    padding: 0px !important;
  }

  .amplify-input::placeholder {
    padding-left: .5rem !important;
    font-size: small !important;
  }

  .amplify-heading {
    padding: 0px !important;
    padding-top: .5rem !important;
    text-align: center !important;
    font-size: medium !important;
  }

  .amplify-flex, [type="date"] {
    font-size: small !important;
  }

  .amplify-tabs__list {
    height: 50px !important;
  }

  .amplify-tabs__panel {
    padding: 0px !important;
  }

  .amplify-tabs__panel div form input {
    padding: 0px !important;
  }

  .amplify-text {
    font-size: x-small !important;
  }
}

/* Medium devices (md): Tablets, 640px to 767px */
@media (min-width: 640px) and (max-width: 767px) {
    .amplify-text {
    font-size: x-small !important;
  }
}

/* Large devices (lg): Small laptops, 768px to 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
  .amplify-text {
    font-size: small !important;
  }
}

/* Extra large devices (xl): Laptops/desktops, 1024px to 1279px */
@media (min-width: 1024px) and (max-width: 1279px) {
    .amplify-text {
    font-size: small !important;
  }
}

/* 2XL devices (2xl): Large desktops, 1280px to 1535px */
@media (min-width: 1280px) and (max-width: 1535px) {}

/* 3XL devices (3xl): latest large tech maximizing pixel counts, big screens etc. 1536px and up */
@media (min-width: 1536px) {}


 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /*  */
 /* MEDIA QUERY FOR TABLET SIZES (SHORTER HEIGHTS AND SMART DISPLAYS)  */

 @media only screen and (max-height: 600px) {
  .amplify-tabs__panel.amplify-tabs__panel--active div form {
    padding: 12px;
  }

  [role="tab"] {
    font-size: small;
  }

  [role="switch"] {
    height: 1.5rem !important;
  }


  [type="submit"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2rem;
  }

  .amplify-button--small {
  background: none;
  color: var(--vercee-purple);
  padding: 0px !important;
}

  .amplify-input::placeholder {
    font-size: small !important;
  }

  .amplify-heading {
    padding: 0px !important;
    padding-top: .5rem !important;
    text-align: center !important;
    font-size: medium !important;
  }

  .amplify-field {
    font-size: small;
    display: flex;
    margin-bottom: 2rem;
  }

  .amplify-textfield {
    font-size: small;
    display: flex;
    height: 1rem;
  }

  .amplify-passwordfield {
    font-size: small;
    display: flex;
    height: 1rem;
  }

  .amplify-tabs__list {
    height: 4rem !important;
  }

  .amplify-tabs__panel {
    padding: 1rem !important;
  }

  .amplify-tabs__panel div form input {
    padding: .2rem !important;
    height: 1.5rem;
  }

  .amplify-text {
    font-size: small !important;
  }
}
