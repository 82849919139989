/* use this css file to create custom classnames for styling to use throughout the app that otherwise would conflict with the default index.css styling*/

.free-cta-container button.amplify-button {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.free-cta-container button.amplify-button:hover {
    border-top: .1rem solid white !important;
    border-bottom: .1rem solid white !important;
    border-left: none;
    border-right: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

#cta button:hover {
  -webkit-box-shadow: 0px 0px 15px #ffffff !important;
     -moz-box-shadow: 0px 0px 15px #ffffff !important;
          box-shadow: 0px 0px 15px #ffffff !important;
   outline: none !important;
}

.text-shadow {
    text-shadow: 0px 0px 5px pink;
}

.large-text-shadow {
    text-shadow: 0px 0px 15px pink;
}

.glow {
   box-shadow: 0px 0px 20px white;
}

.glow-large {
   box-shadow: 0px 0px 50px white;
}

.hover-glow:hover {
   box-shadow: 0px 0px 20px white;
}

.hover-glow-large:hover {
   box-shadow: 0px 0px 50px white;
}

.accordion-item button:hover {
    box-shadow: none !important;
    text-decoration: underline;
}

.accordion-item button:active {
    background-color: var(--vercee-deepPurple);
}

.subscription-section button:hover {
  position: relative;
  bottom: .1rem;
  -webkit-box-shadow: 0px 0px 15px #ffffff !important;
     -moz-box-shadow: 0px 0px 15px #ffffff !important;
          box-shadow: 0px 0px 15px #ffffff !important;
   outline: none !important;
}


.vjs-volume-menu-button {
  position: relative;
}

.vjs-menu-content {
  width: 100px; /* Adjust slider width for mobile */
}

.terms-page-container p, .terms-page-container li, .privacy-page-container p, .privacy-page-container li {
  /* background-color: red; */
  margin-left: 15px;
}

strong {
  text-decoration: underline;
}
