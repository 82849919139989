@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  --amplify-components-button-focus-border-color: transparent;
  --amplify-components-button-border-color: transparent;
  --amplify-components-button-hover-border-color: transparent;
  --amplify-internal-button-focus-box-shadow: var(--vercee-rose);
  height: 100% !important;
  width: 100% !important;
  overflow-x: hidden !important;
  background-color: var(--gradient);
  margin: 0;
  padding: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  min-width: 240px !important;
  font-family: 'Carlito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* For Firefox */
* {
  scrollbar-width: thin; /* 'auto' | 'thin' | 'none' */
  scrollbar-color: var(--vercee-purple) var(--vercee-deep-purple);
  /* Thumb color and track color */
}

/* For Chrome, Safari, Edge (Webkit) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--vercee-deep-purple); /* Dark background for the track */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
  background: var(--vercee-purple); /* Primary color for the thumb */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid var(--vercee-deep-purple); /* Border creates spacing around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: var(--vercee-light-purple); /* Lighter color on hover */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.master-container {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


code {
  font-family: 'Carlito', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: none !important;
  -webkit-box-shadow: 0px 0px 5px #ffffff !important;
     -moz-box-shadow: 0px 0px 5px #ffffff !important;
          box-shadow: 0px 0px 5px #ffffff !important;
}

button:hover {
  position: relative;
  bottom: .1rem;
  -webkit-box-shadow: 0px 0px 5px #ffffff !important;
     -moz-box-shadow: 0px 0px 5px #ffffff !important;
          box-shadow: 0px 0px 5px #ffffff !important;
   outline: none !important;
}

button:active {
  position: relative;
  top: .1rem;
  background-color: #6305d7;
  -webkit-box-shadow: inset 0px 0px 5px #000000 !important;
     -moz-box-shadow: inset 0px 0px 5px #000000 !important;
          box-shadow: inset 0px 0px 5px #000000 !important;
   outline: none !important;
}

#mc-embedded-subscribe:hover {
  box-shadow: 0 0 2px 2px white;
}

#mc-embedded-subscribe:active {
  background: #a22b4b !important;
}

test {
  background: #010001
}

/* SLICK SLIDE CSS */
/* Exclude slider buttons */
.slick-slider button {
  all: unset !important;
}

.slick-slider button:hover {
  position: static !important;
  box-shadow: none !important;
}

.slick-slider button:active {
  position: static !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.slick-next::before, .slick-prev::before, button.slick-arrow.slick-prev, button.slick-arrow.slick-next {
    display: none !important;
}

.slick-slider {
  padding: 5px;
}

.slick-list {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.slick-slide {
  padding-left: 12px;
  padding-right: 12px;
}

.slick-track {
  margin-bottom: 0px;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
}

.slick-dots > li {
  list-style: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  background-color: white;
  border-radius: 50%;
  width: 12px !important;
  height: 12px !important;
  color: black;
}

.slick-dots > .slick-active {
  background-color: var(--vercee-rose);
}

@media (max-width: 1024px) {
  .slick-list {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }

  .slick-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
}
